import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import {
  AuthProvider,
  AuthContext,
} from "./component/Authentication/AuthContext";

import Header from "./pages/Header";
import MobileNavbar from "./pages/MobileNavbar";
import Registeration from "./pages/Registeration";
import Footer from "./component/Footer";
import NotFound from "./pages/NotFound";
// import ProtectedRoute from "./component/ProtectedRoute";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import * as Cronitor from "@cronitorio/cronitor-rum-js";
import Login from "./pages/LoginPage";
import FullScreenLoading from "./component/LoadingScreen/FullScreenLoading";
import ReactGA from 'react-ga';




const Home = lazy(() => import("./pages/Home"));
const TournamentPage = lazy(() => import("./pages/Tournament-Page"));
const Tournaments = lazy(() => import("./pages/Tournament-List"));
const About = lazy(() => import("./pages/About"));
const Organizer = lazy(() => import("./pages/Organizer"));
const Admin = lazy(() => import("./pages/Admin"));
const Contact = lazy(() => import("./pages/Contact"));
const UserProfile = lazy(() => import("./pages/UserProfile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));
const Settings = lazy(() => import("./component/SettingsMenu"));

const MainRoutes = () => {
  const { isAuthenticated, isDeveloper, isStaff, isLoading } =
    useContext(AuthContext);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tournaments" element={<Tournaments />} />
      <Route path="/tournament/*" element={<TournamentPage />} />
      <Route path="/tournament/:id/*" element={<TournamentPage />} />
      <Route path="/t/*" element={<TournamentPage />} />
      <Route path="/t/:id/*" element={<TournamentPage />} />

      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/profile/*" element={<UserProfile />} />
      <Route path="/profile" element={<UserProfile />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/registeration/*" element={<Registeration />} />
      <Route
        path="/login"
        element={
        
            <Login isAuthenticated={isAuthenticated} navigate={Navigate} />
 
        }
      />
      <Route
        path="/admin"
        element={
          <SuperAdminProtected>
            <Admin />
          </SuperAdminProtected>
        }
      />
      <Route
        path="/admin/*"
        element={
          <SuperAdminProtected>
            <Admin />
          </SuperAdminProtected>
        }
      />
       <Route
        path="/organizer/*"
        element={
          <SuperAdminProtected>
            <Organizer />
          </SuperAdminProtected>
        }
      />
      <Route
        path="/organizer"
        element={
          <SuperAdminProtected>
            <Organizer />
          </SuperAdminProtected>
        }
      />

      {/* Other routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    refreshSidebar();
    window.scrollTo(0, 0);
    console.log(currentPath);
    ReactGA.initialize(371242211);
    ReactGA.event({
      category: 'User',
      action: 'Created an Account'
    });
  }, [currentPath]);

  const refreshSidebar = () => {
    const sidebar = document.getElementById("sidebarMenu");
    const mainNavbar = document.getElementById("main-navbar");
 

    const sidebarMenu = document.getElementById("sidebarMenu");

    if (currentPath != "/") {
      sidebar.style.visibility = "visible";
      sidebarMenu.style.visibility = "visible";
      sidebarMenu.classList.remove("move-to-left");
      sidebarMenu.classList.add("move-to-right");
      mainNavbar.classList.add("active");
 
    } else if (window.location.pathname === "/") {
      sidebar.style.visibility = "hidden";
      // mainNavbar.classList.remove("active");
    } else if (window.location.pathname === "/login") {
      sidebar.style.visibility = "hidden";
      // mainNavbar.classList.remove("active");
    } else {
      sidebar.style.visibility = "visible";
      sidebarMenu.style.visibility = "visible";
      sidebarMenu.classList.remove("move-to-left");
      sidebarMenu.classList.add("move-to-right");
      mainNavbar.classList.add("active");
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="page-container">
          <Suspense fallback={<FullScreenLoading />}>
            <MainRoutes />
          </Suspense>
        </div>
      </main>
      <MobileNavbar />
      <Footer />
    </>
  );
};

function SuperAdminProtected({ children }) {
  console.log('super admin protected')
  const { isAuthenticated, isDeveloper, isLoading } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && (!isAuthenticated || !isDeveloper)) {
      navigate("/login");
    }

  }, [isAuthenticated, isDeveloper, isLoading, navigate]);

  return isAuthenticated && isDeveloper ? children : null;
}

function StaffProtected({ children }) {
  const { isAuthenticated, isDeveloper, isStaff, isLoading } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && (!isAuthenticated || !isStaff || !isDeveloper)) {
      navigate("/login");
    }

  }, [isAuthenticated, isDeveloper, isStaff, isLoading, navigate]);

  return isAuthenticated && (isStaff || isDeveloper) ? children : null;


}

function Protected({ children }) {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}

const WrappedApp = () => {
  return (
    <AuthProvider>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default WrappedApp;
