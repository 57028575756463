import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const TwitterRegistration = () => {
  // Your Twitter Registration Component
  return <div>Twitter Registration</div>;
};

const DiscordRegistration = () => {
  // Your Discord Registration Component
  return <div>Discord Registration</div>;
};

const Registration = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  useEffect(() => {
    MySwal.fire({
      title: 'Enter your email address',
      input: 'email',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      preConfirm: (emailInput) => {
        if (!emailInput) {
          Swal.showValidationMessage('Please enter your email address');
        }
        return emailInput;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setEmail(result.value);
        fetch('/api/register', {  // Replace with your API endpoint
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: result.value })
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'ok') {
            MySwal.fire('Registered!', 'You have been successfully registered.', 'success');
          } else {
            MySwal.fire('Oops!', 'There was a problem registering. Please try again.', 'error');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          MySwal.fire('Oops!', 'There was a problem registering. Please try again.', 'error');
        });
      }
    });
  }, []);

  return (
    <div className="page-content-wrapper">
      <Routes>
        <Route path="twitter" element={<TwitterRegistration />} />
        <Route path="discord" element={<DiscordRegistration />} />
      </Routes>
    </div>
  );
};

export default Registration;
