import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../../component/Authentication/AuthContext';
import classNames from 'classnames';
import ShowSettingsMenu from '../../../../component/SettingsMenu';
import styles from './MobileFooter.module.css';
import {showLoginPopup} from '../../../../component/Authentication/LoginPopup';
const MobileFooter = () => {
  const { isAuthenticated, isDeveloper, hasEarlyAccess, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isProfileMenuVisible, setProfileMenuVisible] = useState(false);
  const [isWalletMenuVisible, setWalletMenuVisible] = useState(false);
  const [isSidebarMenuVisible, setSidebarMenuVisible] = useState(false); // renamed

  const handleSidebarMenuButtonClick = () => { // renamed
    setSidebarMenuVisible(!isSidebarMenuVisible);
    setProfileMenuVisible(false);
    setWalletMenuVisible(false);
  };
  const handleSidebarButtonClick = (path) => {
    navigate(path);
    setSidebarMenuVisible(false);
  };
  const handleWalletButtonClick = () => {
    setWalletMenuVisible(!isWalletMenuVisible);
    setProfileMenuVisible(false);
    setSidebarMenuVisible(false);
    
  };

  const handleProfileButtonClick = () => {
    setProfileMenuVisible(!isProfileMenuVisible);
    setWalletMenuVisible(false);
    setSidebarMenuVisible(false);
  
    // Toggle off the profile menu when "My Profile" button is clicked
    if (isProfileMenuVisible) {
      setProfileMenuVisible(false);
    }
  };
  

  return (
    <div className={styles.footer}>
      {[
        { path: '/', icon: 'fa-home', text: 'Home' },
        // { path: '/tournaments', icon: 'fa-trophy', text: 'Tournaments' },
      ].map(({ path, icon, text }) => (
        <button
          key={path}
          className={classNames(pathname === path ? styles.active : '', {
            [styles.active]: pathname === path,
          })}
          onClick={() => {
            navigate(path);
            setProfileMenuVisible(false);
            setWalletMenuVisible(false);
          }}
        >
          <i className={`${styles.footerBtn} fas ${icon}`}></i>
          <span>{text}</span>
        </button>
      ))}

      {isAuthenticated && hasEarlyAccess && (
        <div className={styles.walletContainer}>
          <button
            className={classNames(styles.walletButton, {
              [styles.active]: isWalletMenuVisible,
            })}
            onClick={handleWalletButtonClick}
          >
            <i className={`${styles.footerBtn} fas fa-wallet`}></i>
            <span>Wallet</span>
          </button>
          {isWalletMenuVisible && (
            <div className={classNames(styles.walletMenu, styles.visible, styles.glassEffect)}>
              <div className={styles.walletTitle}>WALLET</div>
              <div className={styles.walletInfo}>
                <img className={styles.userImage} src={user.displayImage} alt="User Avatar" />
                <div>
                  <div className={styles.username}>{user.displayName}</div>
                  <div className={styles.balance}>
                    <img className={styles.coinIcon} src="/images/icons/coin.png" alt="Coin" /> Balance:{' '}
                    {new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(
                      user.wallet.tokenBalance
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

<button
             
             onClick={() => {handleSidebarButtonClick("/tournaments");}}
           >
             <i className={`${styles.footerBtn} fas fa-trophy`}></i>
             <span>Tournaments</span>
           </button>
{!isAuthenticated && (
                <button
             
                onClick={showLoginPopup}
              >
                <i className={`${styles.footerBtn} fas fa-user`}></i>
                <span>Login</span>
              </button>
)}
 {isAuthenticated && (
<button
              className={classNames(styles.profileButton, { [styles.active]: isProfileMenuVisible })}
              onClick={handleProfileButtonClick}
            >
              <i className={`${styles.footerBtn} fas fa-user`}></i>
              <span>Profile</span>
            </button>
 )}
      {isProfileMenuVisible && (
        <>
              
        <div className={styles.profileMenu}>
    
              <div className={styles.profileMenuBtn} onClick={() => {navigate('/profile'); setProfileMenuVisible(false) }}>
                My Profile
              </div>
              {hasEarlyAccess && (
                <div className={styles.profileMenuBtn} onClick={() =>{ShowSettingsMenu() ; setProfileMenuVisible(false)}}>
                  Settings
                </div>
              )}
              <div className={styles.profileMenuBtn}      onClick={() => {( window.location.href = "/logout"); setProfileMenuVisible(false)}}>
                Logout
              </div>
              </div>
            </>
     
    
      )}
      
      <button
  className={classNames(styles.sidebarMenuButton, { [styles.active]: isSidebarMenuVisible })}
  onClick={handleSidebarMenuButtonClick}
>
  <i className={`${styles.footerBtn} fas fa-bars`}></i>
  <span>Menu</span>
</button>


      {isSidebarMenuVisible && (
  <div className={styles['sidebard-list-menu'] + ' ' + (isSidebarMenuVisible ? styles.open : '')}>
    
    <div
      className={styles.sidebarMenuBtn}  
      onClick={() => {handleSidebarButtonClick("/");}}
    >
      <i className="fa-solid fa-house fa-fw "></i>
     <span>Home</span> 
    </div>

      <div
        className={styles.sidebarMenuBtn}  
        onClick={() => {handleSidebarButtonClick("/tournaments");}}
      >
        <i className="fa-solid fa-trophy fa-fw "></i>
        <span>Tournaments </span>
      </div>

    {isAuthenticated && user && isDeveloper && (
      <div
        className={styles.sidebarMenuBtn}  
        onClick={() => {handleSidebarButtonClick("/admin");}}
      >
        <i className="fa-solid fa-house fa-fw "></i>
        <span>Admin Panel</span>
      </div>
    )}

    {isAuthenticated && user && isDeveloper && (
      <>
        <div
          className={styles.sidebarMenuBtn}  
          // onClick={toggleHostSubmenu} 
        >
          <i className="fa-solid fa-cogs fa-fw "></i>
          <span>Organizer</span>
        </div>

        <div
          className={styles.sidebarMenuBtn}  
          onClick={() => {handleSidebarButtonClick("/organizer/dashboard");}}
        >
          <i className="fa-solid fa-user fa-fw "></i>
          <span>Dashboard</span>
        </div>

        <div
          className={styles.sidebarMenuBtn}  
          onClick={() => {handleSidebarButtonClick("/organizer/create-tournament");}}
        >
          <i className="fa-solid fa-bullhorn fa-fw "></i>
          <span>Create Tournament</span>
        </div>

        <div
          className={styles.sidebarMenuBtn}  
          onClick={() => {handleSidebarButtonClick("/contact-us");}}
        >
          <i className="fa-solid fa-address-book "></i>
          <span>Contact Us</span>
        </div>
      </>
    )}

    {/* {!isAuthenticated && (
      <div
        className={styles.sidebarMenuBtn}
        onClick={() => {showLoginPopup();}}
      >
        <i className="fa-solid fa-lock fa-fw me-1"></i>
        <span>Login</span>
      </div>
    )}

    {isAuthenticated && (
      <div
        className={styles.sidebarMenuBtn}
        onClick={() => {( window.location.href = "/logout");  handleSidebarButtonClick("/");}}
      >
        <i className="fa-solid fa-right-from-bracket fa-fw "></i>
        <span>Log Out</span>
      </div>
    )} */}

  </div>
)}


    </div>
  );
};

export default MobileFooter;
