import ReactDOMServer from 'react-dom/server';
import styles from './LoginForm.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

let showLoginPopup;

const initLoginPopup = () => {
  const MySwal = withReactContent(Swal);

  showLoginPopup = () => {
    const popupContent = (
      <form>
        <div  style={{fontSize: "20px", textDecoration: "none", color: "white"}}>
          <div className="logo-inner"> 
            <h1 className="logo-text" style={{fontSize: "35px"}}>
              <span className="red-text">PLAYER</span>
              ZONE
            </h1>
          </div>
        </div>
        <div className={styles.title_container}>
          <p className={styles.title}>Login to your Account</p>
          <span className={styles.subtitle}>
            Get started with our app, just create an account and enjoy the experience.
          </span>
        </div>
        <div className={styles.separator}>
          <hr className={styles.line} />
          <hr className={styles.line} />
        </div>
        <div className={`${styles.socialBtnsContainer}`}>
          <button
            className={`${styles.discordBtn} discord-btn`}
            onClick={(event) => {
                event.preventDefault();
                window.location.replace('/auth/discord');
              }}
              
          >
            <i className="fab fa-discord"></i> Sign in / Sign up with Discord
          </button>
        </div>
      </form>
    );

    MySwal.fire({
      html: ReactDOMServer.renderToString(popupContent),
      showConfirmButton: false,
      didOpen: () => {
        const discordBtn = document.querySelector('.discord-btn');
        discordBtn.blur();
        discordBtn.addEventListener('click', (event) => {
          event.preventDefault();
          window.location.replace('/auth/discord');
        });
      },
    });
  };
};

initLoginPopup();

export { showLoginPopup };
