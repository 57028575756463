import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './index.css';

import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

unregister();
// registerServiceWorker();
