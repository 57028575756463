
import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import styles from './LoginForm.module.css'; // Import the CSS module for the login form component
import './styles.css'; // Export the styles
import LoginButton from '../../component/Authentication/LoginAlert/LoginButton';
const LoginForm = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(props.isAuthenticated){
      navigate("/");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission logic here
  };



  return (
    <div className="page-content-wrapper">
    <div className={styles.container}>
    <form className={`${styles.form_container} card-bg-color card-border`} onSubmit={handleSubmit}>
    <a   href="/" style={{fontSize: "20px", textDecoration: "none", color: "white" }}>
                <div className="logo-inner"> 
                <h1 className="logo-text" style={{fontSize: "35px"}}>
                  <span className="red-text">PLAYER</span>
                  ZONE
                 
                </h1>
                {/* <span className="beta-text">BETA</span> */}
                </div>
           
            </a>
      {/* <div className={styles.logo_container}></div> */}
      <div className={styles.title_container}>
        <p className={styles.title}>Login to your Account</p>
        <span className={styles.subtitle}>
          Get started with our app, just create an account and enjoy the
          experience.
        </span>
      </div>
      <br />
      {/* <div className={styles.input_container}>
        <label className={styles.input_label} htmlFor="email_field">
          Email
        </label>

        <input
          placeholder="name@mail.com"
          title="Input title"
          name="input-name"
          type="text"
          className={styles.input_field}
          id="email_field"
        />
      </div>
      <div className={styles.input_container}>
        <label className={styles.input_label} htmlFor="password_field">
          Password
        </label>

        <input
          placeholder="Password"
          title="Input title"
          name="input-name"
          type="password"
          className={styles.input_field}
          id="password_field"
        />
      </div> */}
      {/* <button title="Sign In" type="submit" className={styles.sign_in_btn}>
        <span>Sign In</span>
      </button> */}

      <div className={styles.separator}>
        <hr className={styles.line} />
        {/* <span>Or</span> */}
        <hr className={styles.line} />
      </div>
      {/* Google and Apple Sign In buttons */}
      {/* Add the corresponding SVGs and the required logic for third-party sign-ins */}
      {/* <button title="Sign In" type="submit" className={styles.sign_in_ggl}>
        <span>Sign In with Google</span>
      </button>
      <button title="Sign In" type="submit" className={styles.sign_in_apl}>
        <span>Sign In with Apple</span>
      </button> */}
       {/* <LoginButton
                buttonName="btn navbar-login btn-outline-danger btn-rounded btn-sm px-3"
                buttonIClass=""
                buttonContent="LOGIN"
              /> */}
              <div className={styles.socialBtnsContainer}>  <button
          className="discord-btn btn navbar-login btn-outline-primary btn-rounded btn-sm px-3"
          onClick={() => (window.location.href = '/auth/discord')}
        >
          <i className="fab fa-discord"></i> Sign in / Sign up with Discord
        </button></div>
             
    </form>
    </div>
    </div>
  );
};

export default LoginForm;
