import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import { AuthContext } from "../../component/Authentication/AuthContext";


const Footer = () => {
  const { isAuthenticated, isDeveloper,isStaff, isLoading } = useContext(AuthContext);

  return (
    <>
    {isLoading ? ( '' ) : (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerContent}>
        <div className={styles.footerSocialMedia}>
        <a href="https://twitter.com/PlayerZoneGG" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="mailto:information@playerzone.gg"> information@playerzone.gg</a>
            </div>
          <div className={styles.footerSocialMedia}>
         
       
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
          
           
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>&copy; {new Date().getFullYear()} PlayerZone</p>
        </div>
      </div>
    </footer>
    )}
    </>
  );
};

export default Footer;
