import React from "react";
import "./style.css";

const LoadingScreen = () => {
  return (
    <div className="loading-container d-flex justify-content-center align-items-center">
      <div className="loading-spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;