import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import MobileFooter from './components/Navbar/MobileFooter';

class MobileNavbar extends Component {
  render() {
    return (
      <div className="MobileNavbar mt-auto">
        <MobileFooter />
      </div>
    );
  }
}

export default MobileNavbar;
