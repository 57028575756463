import React, { createContext, useState, useEffect } from 'react';
import { showLoginPopup } from "./LoginPopup";
const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  isSuperAdmin: false,
  isDeveloper: false,
  hasBetaAccess: false,
  hasEarlyAccess: false,
  isStaff: false,
  isLoading: false,
  login: () => {showLoginPopup()},
  logout: () => {},
  isLoading: true,
});

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    isSuperAdmin: false,
    isDeveloper: false,
    hasBetaAccess: false,
    hasEarlyAccess: false,
    isStaff: false,
    isLoading: false,
  });

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await fetch('/api/private/get/userinfo', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Send cookies with the request
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data?.user) {
        setAuthState({
          isAuthenticated: true,
          user: data.user || null,
          isSuperAdmin: data.user.isSuperAdmin || false,
          isDeveloper: data.user.isDeveloper || false,
          hasEarlyAccess: data.user.hasEarlyAccess || false,
          hasBetaAccess: data.user.hasBetaAccess || false,
          isStaff: data.user.isStaff || false,
          isLoading: false,
        });
      } else {
        setAuthState({
          isAuthenticated: false,
          user: null,
          isSuperAdmin: false,
          isDeveloper: false,
          hasBetaAccess: false,
          hasEarlyAccess: false,
          isStaff: false,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setAuthState(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const login = async (userData) => {
    try {
      showLoginPopup();
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const logout = async () => {
    try {
      const response = await fetch('/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Send cookies with the request
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAuthState({
        isAuthenticated: false,
        user: null,
        isSuperAdmin: false,
        isDeveloper: false,
        hasBetaAccess: false,
        hasEarlyAccess: false,
        isStaff: false,
        isLoading: false,
      });
      navigate("/");
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{...authState, login, logout}}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
