import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import LoginButton from "../../component/Authentication/LoginAlert/LoginButton";

import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../component/Authentication/AuthContext";
import MainNavbarLinks from "./components/main-nav-links/MainNavbarLinks";
import { Dropdown } from "react-bootstrap";

import { showLoginPopup } from "../../component/Authentication/LoginPopup";
import {
  useParams,
  useMatch,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "./sidebar-nav.css";
import "./main-nav.css";
import "./style.css";
import { startsWith } from "lodash";

const mobile_max_width = 991;

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Add this line
  const {
    isAuthenticated,
    user,
    hasEarlyAccess,
    isDeveloper,
    isLoading,
    login,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [howItWorksSubmenu, setHowItWorksSubmenu] = useState(false);
  const [hostingSubmenu, setHostingSubmenu] = useState(false);

  useEffect(() => {
    const mainNavbar = document.getElementById("main-navbar");
    // const overlay = document.querySelector(".overlay");
    mainNavbar.classList.add("active");
    mainNavbar.classList.add("sticky");

    // overlay.classList.add("visible");
    setSidebarOpen(false); // Close the sidebar whenever the current path changes
  }, [currentPath]);
  const toggleHowItWorksSubmenu = () => {
    setHowItWorksSubmenu(!howItWorksSubmenu);
  };

  const toggleHostSubmenu = () => {
    setHostingSubmenu(!hostingSubmenu);
  };

  const handleLogin = async () => {
    try {
      // showLoginAlert();
      // navigate("/login");
      login();
    } catch (error) {
      console.error("Error during Discord authentication:", error);
    }
  };

  return (
    // Add the 'home-page' class when it's the home page
    <header>
      <nav
        id="sidebarMenu"
        className={`collapse d-lg-block sidebar  collapse  ${
          sidebarOpen ? "show" : ""
        }`}
        style={{ visibility: "hidden" }}
      >
        <div className="sidebar-container">
       
          {isAuthenticated && (
            <div className="profile-card">
              <div className="card__img">
                <img
                  src={user.displayImage}
                  className=""
                  alt="Avatar"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/no-user.png";
                    e.target.style.backgroundImage = "none";
                  }}
                />
              </div>
              <div className="card__name  d-flex flex-column justify-content-center align-items-center">
                <div>
                  <span>{user.displayName}</span>
                </div>

                {user.roles[0] && (
                  <div className=" badge_role">{user.roles[0].name}</div>
                )}
              </div>
            </div>
          )}
          {!isAuthenticated && (
            <div className="profile-card">
              <div className="card__img">
                <img
                  src={`/images/no-user.png`}
                  className=""
                  alt="Avatar"
                  loading="lazy"
                />
              </div>
              <div className="card__name  d-flex flex-column justify-content-center align-items-center">
                <div>
                  <span>Guest</span>
                </div>
              </div>
            </div>
          )}
          <div className="list-group list-group-flush mt-4 d-flex flex-column justify-content-center text-center">
            <div className="list-group sidebard-list-menu d-flex flex-column justify-content-center text-center">
              <a
                onClick={() => {
                  navigate("/");

                  setSidebarOpen(false);
                }}
                className={`sidebar-btn list-group-item list-group-item-action py-2 ripple ${
                  currentPath === "/" ? "active" : ""
                }`}
              >
                <i className="fa-solid fa-house fa-fw me-2"></i>
                Home
              </a>
              <a
                onClick={() => {
                  navigate("/tournaments");
                }}
                className={`sidebar-btn  py-2 ripple ${
                  currentPath === "/tournaments" ? "active" : ""
                }`}
              >
                <i className="fa-solid fa-trophy fa-fw me-2"></i>
                <span>Tournaments</span>
              </a>

              {isAuthenticated && user && isDeveloper && (
                <a
                  onClick={() => navigate("/admin")}
                  className={`sidebar-btn  py-2 ripple${
                    currentPath === "/admin" ? " active" : ""
                  }`}
                >
                  <i className="fa-solid fa-house fa-fw me-2"></i>
                  Admin Panel
                </a>
              )}
              {/* <button
                onClick={toggleHowItWorksSubmenu}
                className={`sidebar-btn how-it-works-button-container list-group-item list-group-item-action py-2 ripple ${
                  howItWorksSubmenu ? "active" : ""
                }`}
              >
                <i className="fa-solid fa-cogs fa-fw me-2"></i>
                <span>How it Works</span>
              </button>
              <div
                className={`how-it-works-submenu ${
                  howItWorksSubmenu ? "visible" : ""
                }`}
              >
                <a
                  onClick={ () => navigate("/how-it-works/players") }
                  className=" how-it-works-button list-group-item list-group-item-action py-2 ripple"
                >
                  <i className="fa-solid fa-user fa-fw me-2"></i>
                  <span>For Players</span>
                </a>
                <a
                  onClick={ () => navigate("/how-it-works/organizers") }
                  className="how-it-works-button list-group-item list-group-item-action py-2 ripple"
                >
                  <i className="fa-solid fa-bullhorn fa-fw me-2"></i>
                  <span>For Organizers</span>
                </a>
              </div> */}

              {isAuthenticated && user && isDeveloper && (
                <>
                  <button
                    onClick={toggleHostSubmenu}
                    className={`sidebar-btn how-it-works-button-container  py-2  ${
                      hostingSubmenu ? "" : ""
                    }`}
                  >
                    <i className="fa-solid fa-cogs fa-fw me-2"></i>
                    <span>Organizer</span>
                  </button>
                  <div
                    className={`how-it-works-submenu ${
                      hostingSubmenu ? "visible" : ""
                    }`}
                  >
                    <a
                      onClick={() => navigate("/organizer/dashboard")}
                      className=" how-it-works-button list-group-item list-group-item-action py-2 ripple"
                    >
                      <i className="fa-solid fa-user fa-fw me-2"></i>
                      <span>Dashboard</span>
                    </a>
                    <a
                      onClick={() => navigate("/organizer/create-tournament")}
                      className={`how-it-works-button list-group-item list-group-item-action py-2 ripple ${
                        startsWith(currentPath, "/organizer/create-tournament")
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="fa-solid fa-bullhorn fa-fw me-2"></i>
                      <span>Create Tournament</span>
                    </a>
                  </div>
                </>
              )}
              {isAuthenticated && user && isDeveloper && (
                <a
                  onClick={() => navigate("/contact-us")}
                  className={`sidebar-btn py-2 ripple ${
                    startsWith(currentPath, "/contact-us") ? "active" : ""
                  }`}
                >
                  <i className="fa-solid fa-address-book me-2"></i>
                  <span>Contact Us</span>
                </a>
              )}

              {/* <a
                href="/tournaments"
                className="sidebar-btn list-group-item list-group-item-action py-2 ripple"
              >
                <i className="fa-solid fa-address-book me-2"></i>
                <span>Contact Us</span>
              </a> */}

              {!isAuthenticated && (
                <a
                  className="sidebar-btn list-group-item list-group-item-action py-2 ripple"
                  onClick={() =>
                    // navigate("/login")
                    login()
                  }
                >
                  <i className="fa-solid fa-lock fa-fw me-1"></i>
                  Login
                </a>
                // <LoginButton
                //   buttonName="sidebar-btn list-group-item list-group-item-action py-2 ripple"
                //   buttonIClass="fa-solid fa-lock fa-fw me-1"
                //   buttonContent="Login"
                // />
              )}

              {isAuthenticated && (
                <React.Fragment>
                  <div
                    onClick={() => (window.location.href = "/logout")}
                    className="sidebar-btn list-group-item list-group-item-action py-2 ripple"
                  >
                    <i className="fa-solid fa-right-from-bracket fa-fw me-2"></i>
                    Log Out
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </nav>

      <nav id="main-navbar" className="navbar navbar-expand-lg fixed-top">
        {/* <div className="overlay"></div> */}
        <div className="container-fluid">
          {/* <button
            className="navbar-toggler link-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ border: "transparent" }}
          >
            <i className="fas fa-bars"></i>
          </button> */}

<a
            className="logo-container"
            onClick={() => navigate("/")}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="logo-inner">
              <h1 className="logo-text">
                <span className="red-text">PLAYER</span>
                ZONE
              </h1>
              <span className="beta-text">BETA</span>
            </div>
          </a>
          {/* <MainNavbarLinks /> */}

          <ul className="navbar-nav ms-auto d-flex flex-row">
            {/* {isAuthenticated && !hasEarlyAccess && (
              <a
                onClick={() => navigate("/profile")}
                className="avatar-container"
              >
                <img
                  className="rounded-circle header-avatar"
                  alt="Avatar"
                  loading="lazy"
                  src={user.displayImage}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/no-user.png";
                    e.target.style.backgroundImage = "none";
                  }}
                />
              </a>
            )} */}

            {isAuthenticated && (
              <div className="navbar-user-container">
                {hasEarlyAccess ? (
                  <>
                    <img
                      className="coin-icon"
                      src="/images/icons/coin.png"
                      alt="Coin"
                    />
                    <div className="balance-container">
                      <div className="balance-title">WALLET</div>
                      <div className="balance-value">
                        Bal:{" "}
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                        }).format(user.wallet.tokenBalance)}
                      </div>
                    </div>
                    <a
                      onClick={() => navigate("/profile")}
                      className="avatar-container"
                    >
                      <img
                        className="rounded-circle header-avatar"
                        alt="Avatar"
                        loading="lazy"
                        src={user.displayImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/no-user.png";
                          e.target.style.backgroundImage = "none";
                        }}
                      />
                    </a>
                  </>
                ) : (
                  <a
                    onClick={() => navigate("/profile")}
                    className="avatar-container"
                  >
                    <img
                      className="rounded-circle header-avatar"
                      alt="Avatar"
                      loading="lazy"
                      src={user.displayImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/images/no-user.png";
                        e.target.style.backgroundImage = "none";
                      }}
                    />
                  </a>
                )}
              </div>
            )}

            {!isAuthenticated && (
              <div class="d-flex flex-row gap-2">
              <a
                className="btn navbar-login btn-outline-danger btn-rounded btn-sm px-3"
                onClick={() =>
                  // navigate("/login")
                  login()
                }
              >
                LOGIN
              </a>
              <a
              className="btn navbar-login"  id="navbar-signup-btn"
              onClick={() =>
                // navigate("/login")
                login()
              }
            >
              SIGN UP
            </a>
           
              </div>
            )}
          </ul>
        </div>
        <script></script>
      </nav>
    </header>
  );
};

export default Header;
