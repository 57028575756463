import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { countries } from 'countries-list';

import styles from './index.module.css';

const countryOptions = Object.entries(countries).map(([code, { name }]) => ({
  value: code,
  label: name,
}));

const PersonalInfo = ({ user }) => {
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [dob, setDob] = useState(user?.dob || '');
  const [country, setCountry] = useState(user?.country || '');
  const [isDisplayNameAvailable, setIsDisplayNameAvailable] = useState(true);
  const [isCooldownActive, setIsCooldownActive] = useState(false);

  const cooldownDuration = 2000; // Cooldown duration in milliseconds
  let cooldownTimer = null; // Timer reference for the cooldown

  const handleSubmit = (e) => {
    e.preventDefault();
    checkDisplayNameAvailability();
  };

  const checkDisplayNameAvailability = () => {
    if (isCooldownActive) {
      return; // Don't make the request if cooldown is active
    }

    setIsCooldownActive(true); // Start the cooldown

    // Make an API call to check if the displayName is available
    // You can replace the API endpoint and request configuration with your own

    // Example API call using fetch:
    fetch('/api/private/checkDisplayNameAvailability', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ displayName }),
    })
      .then((response) => response.json())
      .then((data) => {
        const isAvailable = data.isAvailable;
        setIsDisplayNameAvailable(isAvailable);

        // If displayName is available, save the user info
        if (isAvailable) {
          alert('displayName is available');
          // saveUserInfo({ displayName, dob, country });
        }
      })
      .catch((error) => {
        console.error('Error checking displayName availability:', error);
      })
      .finally(() => {
        // Start the cooldown timer
        cooldownTimer = setTimeout(() => {
          setIsCooldownActive(false); // End the cooldown
        }, cooldownDuration);
      });
  };

  useEffect(() => {
    return () => {
      // Clear the cooldown timer on component unmount
      clearTimeout(cooldownTimer);
    };
  }, []);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formContent}>
        <div className={styles.mb3}>
          <label className={styles.label}>Display Name</label>
          <input
            type="text"
            className={`${styles.input} ${isDisplayNameAvailable ? '' : styles.invalidInput}`}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          {!isDisplayNameAvailable && (
            <p
              style={{
                fontSize: '12px',
                width: '100%',
                fontWeight: '400',
                fontStyle: 'italic',
                color: 'red',
                paddingTop: '3px',
              }}
            >
              This display name is already taken. Please choose a different one.
            </p>
          )}
          {isCooldownActive && (
            <p
              style={{
                fontSize: '12px',
                width: '100%',
                fontWeight: '400',
                fontStyle: 'italic',
                color: 'red',
                paddingTop: '3px',
              }}
            >
              Cooldown period in effect. Please wait before checking again.
            </p>
          )}
        </div>

        {/* Date of Birth */}
        <div className={styles.mb3}>
          <label className={styles.label}>Date of Birth</label>
          <input
            type="date"
            className={styles.input}
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>

        <div className={styles.mb3}>
          <label className={styles.label}>Country</label>
          <Select
            options={countryOptions}
            className={styles.select}
            value={countryOptions.find((option) => option.value === country)}
            onChange={(selectedOption) => setCountry(selectedOption.value)}
          />
        </div>

        <div className={styles.mb3}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            className={`${styles.input} ${styles.disabledInput}`}
            value={email}
            disabled={true}
          />
          <p
            style={{
              fontSize: '12px',
              width: '100%',
              fontWeight: '400',
              fontStyle: 'italic',
              color: 'lightgrey',
              paddingTop: '3px',
            }}
          >
            Email cannot be changed at the moment, please contact support!
          </p>
        </div>
      </div>
      <button type="submit" className={styles.button}>
        Save Changes
      </button>
    </form>
  );
};

export default PersonalInfo;
