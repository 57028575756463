import React, { useState,useEffect } from "react";
import { AuthContext } from "../Authentication/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./index.module.css";
import PersonalInfo from "./components/Personal-Info";

import LoadingScreen from "../LoadingScreen/ComponentLoading";
const SecuritySettings = () => <div>Security Settings Component</div>;
const Socials = () => <div>Socials Component</div>;
const Preferences = () => <div>Preferences Component</div>;



const SettingsModal = ({  }) => {
    const [activeButton, setActiveButton] = useState("personalInfo");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    
      useEffect(() => {
        fetchUserInfo();
      }, []);
    
      const fetchUserInfo = async () => {
        try {
          const response = await fetch('/api/private/get/userinfo', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include', // Send cookies with the request
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const data = await response.json();

          if (data?.user) {
            setUser(data.user);
            setIsAuthenticated(true);
            setLoading(false);
            
          } else {
            setIsAuthenticated(false);
            setLoading(false);
            

            
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
          setLoading    (false);
        }
      };

      if (loading) {
        return <LoadingScreen />;
    }
         else{

    return (
            // <div className="page-content-wrapper">
                <div className={styles.panelContainer}>
                <div className={styles.panelHeader}>
                        <h1 className={styles.panelTitle}>USER Settings</h1>
                    </div>
                    <div className={styles.panelSectionContainer}>
                        <div className={styles.panelNavbar}>
                            <button
                                className={`${styles.btn} ${styles.navbarBtn} ${styles.dFlex} primary-button ${
                                    activeButton === "personalInfo" ? 'active' : ""
                                }`}
                                onClick={() => setActiveButton("personalInfo")}
                            >
                                PERSONAL INFO
                            </button>

                            {/* <button
                                className={`${styles.btn} ${styles.navbarBtn} primary-button ${styles.dFlex} ${
                                    activeButton === "security" ? 'active' : ""
                                }`}
                                onClick={() => setActiveButton("security")}
                            >
                                SECURITY
                            </button> */}

                            <button
                                className={`${styles.btn} ${styles.navbarBtn} primary-button ${styles.dFlex} ${
                                    activeButton === "socials" ? 'active' : ""
                                }`}
                                onClick={() => setActiveButton("socials")}
                            >
                                SOCIALS
                            </button>

                            {/* <button
                                className={`${styles.btn} ${styles.navbarBtn} primary-button ${styles.dFlex} ${
                                    activeButton === "preferences" ? 'active' : ""
                                }`}
                                onClick={() => setActiveButton("preferences")}
                            >
                                PREFERENCES
                            </button> */}
                        </div>
                        <div className={`${styles.panelContent} w-100`}>
                            
                            {activeButton === "personalInfo" && <PersonalInfo user={user} />}
                            {/* {activeButton === "security" && <SecuritySettings />} */}
                            {activeButton === "socials" && <Socials />}
                            {/* {activeButton === "preferences" && <Preferences />} */}
                        </div>
                    </div>
                </div>
            // </div>
        );
                        }  

    
}
const ShowSettingsMenu = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      html: <SettingsModal />,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: `${styles.containerClass}`,
        title: "title-class",
        popup: "popup-class",
        header: "header-class",
        closeButton: "close-button-class",
        icon: "icon-class", 
        image: "image-class",
        content: "content-class",
        input: "input-class",
        actions: "actions-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
        footer: "footer-class",
      },
    });
  }
export default ShowSettingsMenu;
